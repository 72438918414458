<template>
  <div>
    <dialog-form-list
      width="1000px"
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
    >
      <!-- 复制节点 -->
      <template v-slot:name>
        <div style="position: relative">
          <el-input v-model="formData.name" :placeholder="$t('base.enter')" maxlength="20" show-word-limit>
            <el-button
              class="selectEquipmentButton"
              v-if="cmd !== 'view'"
              slot="append"
              icon="el-icon-plus"
              @click="openDialog"
            />
          </el-input>
        </div>
      </template>
      <!-- 规格 -->
      <template v-slot:specifications>
        <div style="position: relative">
          <el-input
            v-model="formData.specifications"
            :placeholder="$t('base.enter')"
          >
            <el-button
              class="selectEquipmentButton"
              v-if="cmd !== 'view'"
              slot="append"
              icon="el-icon-plus"
              @click="addCustomFiled"
            />
          </el-input>
        </div>
      </template>
      <!-- 自定义字段 -->
      <template v-slot:customFieldJson>
        <div class="lists_mk">
          <el-row :gutter="10">
            <el-col
              :span="12"
              v-for="(item, index) in customFieldJson"
              :key="index"
            >
              <div class="listItem_mk">
                <div>
                  <el-input
                    class="label_mk"
                    v-model="item.label"
                    :placeholder="$t('equipmentManage.enterTitle')"
                  />
                  <span>：</span>
                </div>
                <div>
                  <el-input
                    class="content_mk"
                    v-model="item.value"
                    :placeholder="$t('equipmentManage.enterContent')"
                  />
                </div>
                <div class="icon_mk" @click="delCustomFiled(index)">
                  <i class="el-icon-circle-close"></i>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
    </dialog-form-list>
    <!-- 复制 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.equipmentFind"
      :columns="columns"
      :formData="dialogFormData"
      :config="dialogConfig"
      :defaultParams="{
        companyId: $store.getters.company.id,
        scopeCompanyId: $store.getters.company.id,
      }"
      :treeProps="{ children: 'children' }"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 类型 -->
      <template v-slot:level="{ row }">
        {{ levelNames[row.level] }}
      </template>
    </dialog-table-list>
  </div>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:设备名称
        {
          tag: 'slot-content',
          label: this.$t('equipmentManage.equipmentName'),
          prop: 'name',
          span: 12,
          rules: {
            noNull: true,
          },
        },
        // lang:所属分组
        {
          tag: 'el-cascader',
          label: this.$t('equipmentManage.equipmentGroup'),
          prop: 'equipmentGroup',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
          },
        },
        // lang: 所属部门
        {
          tag: 'el-cascader',
          label: this.$t('equipmentManage.equipmentUserAccountGroup'),
          prop: 'userAccountGroup',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'uid',
            },
          },
        },
        // lang:出厂编号
        {
          label: this.$t('equipmentManage.equipmentNumber'),
          prop: 'number',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 20,
            showWordLimit: true,
          },
        },
        // lang:设备型号
        {
          label: this.$t('equipmentManage.equipmentModel'),
          prop: 'model',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 20,
            showWordLimit: true,
          },
        },
        // lang:设备规格
        {
          tag: 'slot-content',
          label: this.$t('equipmentManage.equipmentSpecifications'),
          prop: 'specifications',
          span: 12,
        },
        // lang:自定义字段
        {
          tag: 'slot',
          prop: 'customFieldJson',
          span: 24,
        },
        // lang:设备描述
        {
          tag: 'tinymce-text',
          label: this.$t('equipmentManage.equipmentDetail'),
          prop: 'detail',
          span: 24,
          tagProps: {
            height: 150,
          },
        },
        // lang:设备图片
        {
          tag: 'upload-image',
          label:
            this.$t('equipmentManage.equipmentImageSet') +
            `(${this.$t('base.countLimit') + 5 + this.$t('base.count')})`,
          prop: 'imageSet',
          span: 24,
          tagProps: {
            limit: 5,
          },
        },
        // lang:相关附件
        {
          tag: 'upload-file',
          label: this.$t('equipmentManage.equipmentFileSet'),
          prop: 'fileSet',
          span: 24,
        },
      ],
      // 选择设备
      dialogTitle: this.$t('equipmentManage.selectEquipmentTips'),
      showDialogTableList: false,
      levelNames: {
        ONE: this.$t('equipmentManage.equipmentInfo'),
        TWO: this.$t('equipmentManage.partInfo'),
        THREE: this.$t('equipmentManage.faultInfo'),
      },
      columns: [
        // lang:类型
        {
          label: this.$t('equipmentManage.type'),
          prop: 'level',
          width: '140px',
        },
        // lang:所属分组
        {
          label: this.$t('equipmentManage.equipmentGroup'),
          prop: 'equipmentGroup',
          callback(row) {
            return row.equipmentGroup && row.equipmentGroup.name
          },
        },
        // lang:所属部门
        {
          label: this.$t('equipmentManage.equipmentUserAccountGroup'),
          prop: 'userAccountGroup',
          callback(row) {
            return row.userAccountGroup && row.userAccountGroup.name
          },
        },
        // lang:名称
        {
          label: this.$t('equipmentManage.name'),
          prop: 'name',
        },
        // lang:编号
        {
          label: this.$t('equipmentManage.number'),
          prop: 'number',
        },
        // lang:型号
        {
          label: this.$t('equipmentManage.model'),
          prop: 'model',
        },
        // lang:规格
        {
          label: this.$t('equipmentManage.specifications'),
          prop: 'specifications',
        },
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
      ],
      dialogTableRow: null,
      // 选择设备搜索
      dialogFormData: {},
      dialogConfig: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('equipmentManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 自定义字段
      customFieldJson: [],
    }
  },
  mounted() {
    this.setDefaultValue()
    // 查询详情部门
    this.userAccountGroupFindTree()
    // 查询所属分组
    this.equipmentGroupFind()
  },
  methods: {
    // 添加自定义字段
    addCustomFiled() {
      this.customFieldJson.push({
        label: '',
        value: '',
      })
    },
    // 删除自定义字段
    delCustomFiled(index) {
      this.customFieldJson.splice(index, 1)
    },
    // 弹出设备查询
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.tableList.searchList(dialogFormData)
    },
    // 确认弹出，复制信息
    dialogConfirm() {
      if (this.dialogTableRow.level !== 'ONE') {
        this.$element.showMsg(
          this.$t('equipmentManage.selectEquipmentTips'),
          'warning'
        )
        return
      }
      // 名称
      this.formData.name = this.dialogTableRow.name
      // 编号
      this.formData.number = this.dialogTableRow.number
      // 型号
      this.formData.model = this.dialogTableRow.model
      // 规格
      this.formData.specifications = this.dialogTableRow.specifications
      // 描述
      this.formData.detail = this.dialogTableRow.detail
      // 图片
      this.formData.imageSet = this.dialogTableRow.imageSet
      // 相关附件
      this.formData.fileSet = this.dialogTableRow.fileSet
      this.dialogCancel()
    },
    // 取消弹出
    dialogCancel() {
      this.showDialogTableList = false
      this.$refs.tableList.reset()
    },
    // 选择设备
    openDialog() {
      this.showDialogTableList = true
    },
    // 查询部门
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          companyId: this.$store.getters.company.id,
          scope: 'COMPANY',
        },
      }).then((data) => {
        this.config[2].tagProps.options = data
      })
    },
    // 查询所属分组
    equipmentGroupFind() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentGroupFind,
        data: {
          companyId: this.$store.getters.company.id,
          scope: 'COMPANY',
        },
      }).then((data) => {
        this.config[1].tagProps.options = data
      })
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          // 处理所属部门
          this.formData.userAccountGroup = this.$util.getTreeIds(
            this.tableRow.userAccountGroup
          )
          // 所属分组
          this.formData.equipmentGroup = this.$util.getTreeIds(
            this.tableRow.equipmentGroup
          )
          // 处理自定义字段
          if (this.tableRow.customFieldJson) {
            this.customFieldJson = JSON.parse(this.tableRow.customFieldJson)
          }
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      // 处理所属部门
      formData.userAccountGroupUid = formData.userAccountGroup.pop()
      formData.companyUid = this.$store.getters.company.uid
      delete formData.userAccountGroup
      // 处理所属分组
      formData.equipmentGroup = {
        id: formData.equipmentGroup.pop(),
      }
      // 处理自定义字段
      formData.customFieldJson = JSON.stringify(this.customFieldJson)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      formData.scope = 'COMPANY'
      formData.level = 'ONE'
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>
